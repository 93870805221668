import * as React from 'react';
import styled from '@emotion/styled';
import Masonry from 'react-masonry-css';

type Props = {
  children: React.ReactNode;
};

export const MasonryList: React.FC<Props> = ({ children }: Props) => (
  <StyleList>
    <Masonry
      breakpointCols={{
        default: 4,
        1800: 3,
        1100: 2,
        600: 1,
      }}
      columnClassName="masonry-column"
      className="masonry"
    >
      {children}
    </Masonry>
  </StyleList>
);

const StyleList = styled.div`
  padding-bottom: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    padding-bottom: 50px;
  }
  /* MASONRY */
  .masonry {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -50px; /* gutter size offset */
    width: auto;
  }
  .masonry-column {
    padding-left: 50px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .masonry-column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 50px;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .post-list-item {
    margin-bottom: 30px;
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      margin-bottom: 50px;
    }
  }
`;

export default MasonryList;
