import React from 'react';
import { graphql } from 'gatsby';
import { SliceSection } from 'src/components/post/slice-zone/slices';
import type { PostSliceZoneExcerptBlockFragment } from 'types/graphql-type';

type Props = PostSliceZoneExcerptBlockFragment;

export const query = graphql`
  fragment PostSliceZoneExcerptBlock on PrismicPostBodyExcerptBlock {
    primary {
      block_content {
        text
        html
      }
    }
  }
`;

export const ExcerptBlock: React.FC<Props> = ({
  primary: { block_content },
}: Props) => <SliceSection title="概要" content={block_content.html} />;

export default ExcerptBlock;
