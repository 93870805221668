import React from 'react';
import { graphql } from 'gatsby';
import NormalBlock from './slices/normal-block/normal-block';
import ExcerptBlock from './slices/excerpt-block/excerpt-block';
import type {
  PostSliceZoneNormalFragment,
  PostSliceZoneExcerptFragment,
} from 'types/graphql-type';

interface Props {
  allSlices: (
    | PostSliceZoneNormalFragment
    | PostSliceZoneExcerptFragment
    | null
  )[];
}

export const fragmentQuery = graphql`
  fragment PostSliceZoneNormal on PrismicPostBodyNormalBlock {
    id
    slice_type
    ...PostSliceZoneNormalBlock
  }
  fragment PostSliceZoneExcerpt on PrismicPostBodyExcerptBlock {
    id
    slice_type
    ...PostSliceZoneExcerptBlock
  }
`;

export const SliceZone: React.FC<Props> = ({ allSlices }: Props) => {
  const slices = allSlices.map((slice) => {
    // NOTE:
    // type guardが効かないので、primaryのエラーはここでは無視する
    switch (slice?.slice_type) {
      // These are the API IDs of the slices
      case 'normal_block':
        return <NormalBlock key={slice.id} primary={slice.primary} />;
      case 'excerpt_block':
        return <ExcerptBlock key={slice.id} primary={slice.primary} />;
      // case 'body_text':
      //   return <BodyText key={s.id} input={s} />;
      // case 'image':
      //   return <Image key={s.id} input={s} />;
      default:
        return null;
    }
  });
  return <>{slices}</>;
};

export default SliceZone;
