import * as React from 'react';
import { graphql } from 'gatsby';
import { FilterList } from './filter-list';
import type { Props as FilterListProps } from './filter-list';
import type {
  FilterListYearFragment,
  FilterListCategoryFragment,
  FilterListPlaceFragment,
} from 'types/graphql-type';

type Props = {
  defaultYear?: string;
  defaultCategory?: string;
  defaultPlace?: string;
  yearNodes: FilterListYearFragment[];
  categoryNodes: FilterListCategoryFragment[];
  placeNodes: FilterListPlaceFragment[];
  selectedFilters: FilterListProps['selectedFilters'];
};

export const fragment = graphql`
  fragment FilterListYear on PrismicYear {
    id
    uid
    data {
      label
    }
  }

  fragment FilterListCategory on PrismicCategory {
    id
    uid
    data {
      label
    }
  }

  fragment FilterListPlace on PrismicPlace {
    id
    uid
    data {
      label
    }
  }
`;

export const Filters: React.FC<Props> = ({
  defaultYear,
  defaultCategory,
  defaultPlace,
  yearNodes,
  categoryNodes,
  placeNodes,
  selectedFilters,
}: Props) => (
  <div>
    <FilterList
      title="[Year]"
      taxonomy="year"
      selectedFilters={selectedFilters}
      selectedFilter={selectedFilters.year}
      defaultTerm={defaultYear}
      nodes={yearNodes}
    />
    <FilterList
      title="[Category]"
      taxonomy="category"
      selectedFilters={selectedFilters}
      selectedFilter={selectedFilters.category}
      defaultTerm={defaultCategory}
      nodes={categoryNodes}
    />
    <FilterList
      title="[Place]"
      taxonomy="place"
      selectedFilters={selectedFilters}
      selectedFilter={selectedFilters.place}
      defaultTerm={defaultPlace}
      nodes={placeNodes}
    />
  </div>
);

export default Filters;
