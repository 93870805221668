import React, { useContext } from 'react';
import PrismicDom from 'prismic-dom';
import { ListItem } from './list-item';
import { MobileWidthContext } from 'src/contexts/mobile-width-context';
import type { Props as MobileWidthContextProps } from 'src/contexts/mobile-width-context';

export type Props = {
  title: {
    type?: string;
    text?: string;
    spans?: any[];
  }[];
  sub_title: {
    type?: string;
    text?: string;
    spans?: any[];
  }[];
  image: {
    alt?: string;
    dimensions?: any;
    url?: string;
    list: {
      alt?: string;
      dimensions?: any;
      url?: string;
    };
  };
  meta: {
    type?: string;
    text?: string;
    spans?: any[];
  }[];
  body: (ExcerptBlockType | NormalBlockType)[];
};

// for type guard
type ExcerptBlockType = {
  id: string;
  slice_type: string;
  primary: {
    block_content: {
      type: string;
      text: string;
      spans: any[];
    }[];
  };
};

type NormalBlockType = {
  id: string;
  slice_type: string;
  primary: {
    block_content: {
      type: string;
      text: string;
      spans: any[];
    }[];
    block_title: {
      type: string;
      text: string;
      spans: any[];
    }[];
  };
};

const isExcerptBlock: (item: any) => boolean = (
  item: any
): item is ExcerptBlockType => item?.slice_type === 'excerpt_block';

export const ListItemContainerPrismicApi: React.FC<Props> = ({
  title,
  sub_title,
  image,
  meta,
  body,
}: Props) => {
  // ListItemをPrismicApiと共通で使用するので、
  // ここで整形する
  const titleText = title.length > 0 ? title[0].text : undefined;
  const subTitleText = sub_title.length > 0 ? sub_title[0].text : undefined;
  const imageJSX =
    Object.keys(image.list).length > 0 ? (
      <div className="api-image">
        <div>
          <img src={image.list.url} alt={image.alt || titleText} />
        </div>
      </div>
    ) : undefined;
  const metaHtml =
    meta.length > 0 ? PrismicDom.RichText.asHtml(meta) : undefined;
  const { isMobile } = useContext<MobileWidthContextProps>(MobileWidthContext);
  let excerpt = undefined;
  if (!isMobile && body) {
    const excerptBlock = body.find((element) => isExcerptBlock(element));
    if (excerptBlock !== undefined) {
      const excerptRichText = PrismicDom.RichText.asText(
        excerptBlock.primary.block_content
      );
      excerpt = `${excerptRichText.replace(/\r?\n/g, '').substr(0, 300)}…`;
    }
  }
  return (
    <ListItem
      title={titleText}
      sub_title={subTitleText}
      image={imageJSX}
      meta={metaHtml}
      excerpt={excerpt}
    />
  );
};

export default ListItemContainerPrismicApi;
