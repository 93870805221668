import React, { useEffect, useState, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import styled from '@emotion/styled';
import { Loader } from 'src/components/common';
import { Filters } from './filters';
import { List, ListPrismicApi } from 'src/components/post/list';
import { QueryStringContext } from 'src/contexts/query-string-context';
import { LoadedPostsCacheContext } from 'src/contexts/loaded-posts-cache-context';
import type { Props as ListPrismicApiProps } from 'src/components/post/list/list-prismic-api';
import type { Props as QueryStringContextProps } from 'src/contexts/query-string-context';
import type { Props as LoadedPostsCacheContextProps } from 'src/contexts/loaded-posts-cache-context';
import type { PostListFragment, FilterListQuery } from 'types/graphql-type';
import type { Unpacked } from 'types/custom-type';

type FilterType = {
  year?: string;
  category?: string;
  place?: string;
};

type Props = FilterType & {
  posts: PostListFragment;
};

type StaticQueryProps = FilterListQuery;

const query = graphql`
  query FilterList {
    years: allPrismicYear(sort: { order: ASC, fields: uid }) {
      nodes {
        uid
        prismicId
        ...FilterListYear
      }
    }
    categories: allPrismicCategory(sort: { order: ASC, fields: data___order }) {
      nodes {
        uid
        prismicId
        ...FilterListCategory
      }
    }
    places: allPrismicPlace(sort: { order: ASC, fields: data___order }) {
      nodes {
        uid
        prismicId
        ...FilterListPlace
      }
    }
  }
`;

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  return isMounted;
};

const useSelectedFilters = ({ year, category, place }: FilterType) => {
  const { search } = useContext<QueryStringContextProps>(QueryStringContext);
  let selectedFilters: FilterType = {};
  if (search) {
    selectedFilters = queryString.parse(search);
  } else if (year || category || place) {
    if (year) {
      selectedFilters.year = year;
    }
    if (category) {
      selectedFilters.category = category;
    }
    if (place) {
      selectedFilters.place = place;
    }
  }
  return { search, selectedFilters };
};

type UseLoadedPostsProps = Pick<
  StaticQueryProps,
  'years' | 'categories' | 'places'
> &
  ReturnType<typeof useSelectedFilters>;

const getFilters = ({
  selectedFilters,
  years,
  categories,
  places,
}: Pick<
  UseLoadedPostsProps,
  'selectedFilters' | 'years' | 'categories' | 'places'
>) => {
  const filters = {} as FilterType;
  if (selectedFilters.year) {
    if (selectedFilters.year === 'all') {
      filters.year = 'all';
    } else {
      const target = years?.nodes?.find(
        (year) => year?.uid === selectedFilters.year
      );
      if (target) {
        filters.year = target.prismicId;
      }
    }
  }
  if (selectedFilters.category) {
    if (selectedFilters.category === 'all') {
      filters.category = 'all';
    } else {
      const target = categories?.nodes?.find(
        (category) => category?.uid === selectedFilters.category
      );
      if (target) {
        filters.category = target.prismicId;
      }
    }
  }
  if (selectedFilters.place) {
    if (selectedFilters.place === 'all') {
      filters.place = 'all';
    } else {
      const target = places?.nodes?.find(
        (place) => place?.uid === selectedFilters.place
      );
      if (target) {
        filters.place = target.prismicId;
      }
    }
  }
  return filters;
};

const useLoadedPosts = ({
  years,
  categories,
  places,
  search,
  selectedFilters,
}: UseLoadedPostsProps) => {
  const isMounted = useIsMounted();
  const {
    loadedPostsCache,
    setLoadedPostsCache,
  } = useContext<LoadedPostsCacheContextProps>(LoadedPostsCacheContext);
  const [loadedPosts, setLoadedPosts] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    // 動的に取りに行く
    // netlify functions叩く
    async function loadPosts<T>(): Promise<T> {
      const filters = getFilters({
        selectedFilters,
        years,
        categories,
        places,
      });
      const response = await fetch('/api/loadPosts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filters,
        }),
      }).catch((e: Error) => {
        throw new Error(`Fetch error: ${e.message}`);
      });
      const body = (await response.json()) as T;
      return body;
    }

    void (async () => {
      if (search) {
        if (!loadedPostsCache?.[search]) {
          setLoading(true);
          const { results } = await loadPosts<{
            results: Unpacked<ListPrismicApiProps['nodes']>['data'][] | null;
          }>();
          if (isMounted) {
            setLoading(false);
            setLoadedPosts(results);
            setLoadedPostsCache &&
              setLoadedPostsCache({
                ...loadedPostsCache,
                ...{ [search]: results },
              });
          }
        } else {
          const results = loadedPostsCache[search];
          setLoadedPosts(results);
        }
      }
    })();
  }, [
    years,
    categories,
    places,
    search,
    selectedFilters,
    loadedPostsCache,
    setLoadedPostsCache,
    isMounted,
  ]);
  return { loading, loadedPosts };
};

export const ListPage: React.FC<Props> = ({ posts, year, category, place }) => {
  const { search, selectedFilters } = useSelectedFilters({
    year,
    category,
    place,
  });
  const { years, categories, places } = useStaticQuery<StaticQueryProps>(query);
  const { loading, loadedPosts } = useLoadedPosts({
    years,
    categories,
    places,
    search,
    selectedFilters,
  });
  return (
    <section>
      <StyleHeader>
        <StyleTitle>{'開催プログラム'}</StyleTitle>
        <Filters
          defaultYear={year}
          defaultCategory={category}
          defaultPlace={place}
          yearNodes={years.nodes}
          categoryNodes={categories.nodes}
          placeNodes={places.nodes}
          selectedFilters={selectedFilters}
        />
      </StyleHeader>
      <StyleContent className={loading ? 'loading' : ''}>
        {!search && <List {...posts} />}
        {search && !loading && loadedPosts && (
          <ListPrismicApi nodes={loadedPosts} />
        )}
      </StyleContent>
      {loading && <Loader />}
    </section>
  );
};

const StyleHeader = styled.header`
  text-align: center;
  padding-bottom: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    padding-bottom: 50px;
  }
`;

const StyleTitle = styled.h1`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.xxxlarge.sp};
  padding-bottom: ${({ theme }) => theme.margin.content.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xxxlarge.pc};
    padding-bottom: 50px;
  }
`;

const StyleContent = styled.div`
  &.loading {
    min-height: 500px;
  }
`;

export default ListPage;
