import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { FilterButton } from './filter-button';
import type {
  FilterListCategoryFragment,
  FilterListPlaceFragment,
} from 'types/graphql-type';

export type Props = {
  className?: string;
  title: string;
  taxonomy: 'category' | 'place' | 'year';
  selectedFilters: {
    category?: string;
    place?: string;
    year?: string;
  };
  selectedFilter?: string;
  defaultTerm?: string;
  nodes: FilterListCategoryFragment[] | FilterListPlaceFragment[];
};

export const FilterList: React.VFC<Props> = ({
  className,
  title,
  taxonomy,
  selectedFilters,
  selectedFilter,
  defaultTerm,
  nodes,
}) => {
  const isFilterOn = Object.keys(selectedFilters).length > 0;

  const handleClickAll = React.useCallback(() => {
    const clone = { ...selectedFilters };
    delete clone?.[taxonomy];
    const search = queryString.stringify(clone);
    if (search) {
      void navigate(`${window.location.pathname}?${search}`);
    } else {
      void navigate(`${window.location.pathname}?${taxonomy}=all`);
    }
  }, [selectedFilters, taxonomy]);

  const handleClickNormal = React.useCallback(
    (uid) => {
      const clone = { ...selectedFilters };
      if (clone?.[taxonomy] === uid) {
        delete clone?.[taxonomy];
      } else {
        clone[taxonomy] = uid;
      }
      const search = queryString.stringify(clone);
      if (search) {
        void navigate(`${window.location.pathname}?${search}`);
      } else {
        void navigate(`${window.location.pathname}`);
      }
    },
    [selectedFilters, taxonomy]
  );

  return (
    <div className={className ?? ''}>
      <FilterTitle>{title}</FilterTitle>
      <StyleList>
        <StyleListItem>
          <FilterButton
            label="すべて"
            active={
              (!isFilterOn && !defaultTerm) ||
              (isFilterOn && (!selectedFilter || selectedFilter === 'all'))
            }
            onClick={handleClickAll}
          />
        </StyleListItem>
        {nodes &&
          nodes.map((term) => (
            <StyleListItem key={term.id}>
              <FilterButton
                label={term.data?.label}
                active={
                  (!isFilterOn && defaultTerm === term.uid) ||
                  (isFilterOn && selectedFilter === term.uid)
                }
                onClick={() => handleClickNormal(term.uid)}
              />
            </StyleListItem>
          ))}
      </StyleList>
    </div>
  );
};

const FilterTitle = styled.h3`
  line-height: 1.8;
  font-size: ${({ theme }) => theme.fontSize.large.sp};
  font-weight: normal;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
  }
`;

const StyleList = styled.ul`
  list-style: none;
  display: inline-block;
  margin-top: 0.5em;
  li {
    display: inline-block;
    margin-right: 0.6em;
    &::after {
      content: '|';
      margin-left: 0.6em;
    }
    &:last-of-type {
      margin-right: 0;
      &::after {
        content: '';
      }
    }
  }
`;

const StyleListItem = styled.li`
  margin: 0;
  line-height: 1.8;
  font-size: ${({ theme }) => theme.fontSize.normal.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.normal.pc};
  }
`;

export default FilterList;
