import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { MasonryList } from './masonry-list';
import { ListItemContainer } from './list-item-container';
import type { PostListFragment } from 'types/graphql-type';

type Props = PostListFragment;

export const fragment = graphql`
  fragment PostList on PrismicPostConnection {
    nodes {
      id
      uid
      data {
        ...PostArchiveData
      }
    }
  }
`;

export const List: React.FC<Props> = ({ nodes }) => (
  <>
    {nodes.length > 0 && (
      <MasonryList>
        {nodes.map((item) => (
          <Link key={item.id} to={item.uid ? `/event/${item.uid}/` : '/'}>
            <ListItemContainer {...item.data} />
          </Link>
        ))}
      </MasonryList>
    )}
    {nodes.length === 0 && <p>現在投稿はありません。</p>}
  </>
);

export default List;
