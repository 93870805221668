import * as React from 'react';

import styled from '@emotion/styled';

type Props = {
  label?: string | null;
  active: boolean;
  onClick(): void;
};

export const FilterButton: React.FC<Props> = ({ label, active, onClick }) => (
  <StyleFilterButton onClick={onClick} className={active ? 'active' : ''}>
    {label && label}
  </StyleFilterButton>
);

const StyleFilterButton = styled.button`
  text-decoration: none;
  &.active,
  &:hover {
    text-decoration: underline;
  }
`;

export default FilterButton;
