import * as React from 'react';
import styled from '@emotion/styled';
import { Content } from 'src/components/common';

type Props = {
  title: string;
  content: string;
};

export const SliceSection: React.FC<Props> = ({ title, content }) => (
  <StyleSection>
    <StyleTitle>{title}</StyleTitle>
    <StyleContent html={content} />
  </StyleSection>
);

const StyleTitle = styled.h2`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xlarge.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xlarge.pc};
  }
`;

const StyleContent = styled(Content)`
  margin-top: 2.3em;

  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 2.7em;
  }

  p {
    text-align: justify;
  }

  .block-img {
    margin-top: 3em;
    width: 100%;
    & + * {
      margin-top: 3em;
    }
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .caption {
    display: block;
    margin-top: 0.5em;
    font-size: 0.9em;
  }

  a {
    text-decoration: underline;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const StyleSection = styled.section`
  padding-top: 60px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    padding-top: 80px;
  }
`;

export default SliceSection;
