import * as React from 'react';
import styled from '@emotion/styled';
import { PathnameContext } from 'src/contexts/pathname-context';
import type { Props as PathnameContextProps } from 'src/contexts/pathname-context';

type Props = {
  title?: string | null;
  sub_title?: string | null;
  image?: React.ReactNode;
  meta?: string | null;
  excerpt?: string | null;
};

export const ListItem: React.FC<Props> = ({
  title,
  sub_title,
  image,
  meta,
  excerpt,
}) => {
  const { pathname } = React.useContext<PathnameContextProps>(PathnameContext);
  const isHome = pathname === '/';
  return (
    // TODO:
    // homeからの呼び出しの時は、h3に
    <StyleListItem className="post-list-item">
      {image && <StyleImage>{image}</StyleImage>}
      <Header>
        {sub_title && (
          <StyleSubTitle className="post-list-item-sub-title">
            <span>{sub_title}</span>
          </StyleSubTitle>
        )}
        {title && (
          <StyleTitle
            as={isHome ? 'h3' : 'h2'}
            className="post-list-item-title"
          >
            <span>{title}</span>
          </StyleTitle>
        )}
        {meta && <StyleMeta dangerouslySetInnerHTML={{ __html: meta }} />}
      </Header>
      {excerpt && <StyleExcerpt>{excerpt}</StyleExcerpt>}
    </StyleListItem>
  );
};

const StyleImage = styled.figure`
  .gatsby-image-wrapper {
    > div:first-of-type {
      padding-bottom: ${(2 / 3) * 100}% !important;
    }
  }
  .api-image {
    position: relative;
    overflow: hidden;
    > div {
      padding-bottom: ${(2 / 3) * 100}%;
    }
    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

const Header = styled.header`
  margin-top: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 30px;
  }
`;

const StyleSubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.large.sp};
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
  }
`;

const StyleTitle = styled.h2<{ as: string }>`
  margin-top: 18px;
  font-size: ${({ theme }) => theme.fontSize.xxlarge.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xxlarge.pc};
  }
`;

const StyleMeta = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large.sp};
  line-height: 1.8;
  margin-top: 1.5em;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
  }
`;

const StyleExcerpt = styled.p`
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 20px;
    overflow: hidden;
    height: calc(3em * 2);
    @supports (line-clamp) or (-webkit-line-clamp: 3) or (-moz-line-clamp: 3) {
      height: auto;
      display: -moz-box;
      display: -webkit-box;
      display: box;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      -moz-line-clamp: 3;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    display: none;
  }
`;

const StyleListItem = styled.article`
  border-bottom: 1px solid #808080;
  padding-bottom: 20px;
  &:hover {
    img {
      filter: grayscale(1);
    }
    .post-list-item-sub-title,
    .post-list-item-title {
      span {
        border-bottom: 1px solid black;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    padding-bottom: 30px;
  }
`;

export default ListItem;
