import React from 'react';
import { graphql } from 'gatsby';
import { SliceSection } from 'src/components/post/slice-zone/slices';
import type { PostSliceZoneNormalBlockFragment } from 'types/graphql-type';

type Props = PostSliceZoneNormalBlockFragment;

export const query = graphql`
  fragment PostSliceZoneNormalBlock on PrismicPostBodyNormalBlock {
    primary {
      block_title {
        text
      }
      block_content {
        html
      }
    }
  }
`;

export const NormalBlock: React.FC<Props> = ({ primary }: Props) => {
  if (primary?.block_title?.text && primary?.block_content?.html) {
    return (
      <SliceSection
        title={primary?.block_title?.text}
        content={primary?.block_content?.html}
      />
    );
  } else {
    return null;
  }
};

export default NormalBlock;
