import * as React from 'react';
import { Link } from 'gatsby';
import { ListItemContainerPrismicApi } from './list-item-container';
import { MasonryList } from './masonry-list';
import type { Props as ListItemContainerPrismicApiProps } from './list-item-container/list-item-container-prismic-api';

export type Props = {
  nodes: {
    id: string;
    uid: string;
    data: ListItemContainerPrismicApiProps;
  }[];
};

export const ListPrismicApi: React.FC<Props> = ({ nodes }) => (
  <>
    {nodes.length > 0 && (
      <MasonryList>
        {nodes.map((item) => (
          <Link key={item.id} to={`/event/${item.uid}/`}>
            <ListItemContainerPrismicApi
              title={item?.data?.title}
              sub_title={item?.data?.sub_title}
              image={item?.data?.image}
              meta={item?.data?.meta}
              body={item?.data?.body}
            />
          </Link>
        ))}
      </MasonryList>
    )}
    {nodes.length === 0 && <p>現在投稿はありません。</p>}
  </>
);

export default ListPrismicApi;
