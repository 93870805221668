import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ListItem } from './list-item';
import { MobileWidthContext } from 'src/contexts/mobile-width-context';
import type { FluidObject } from 'gatsby-image';
import type { Props as MobileWidthContextProps } from 'src/contexts/mobile-width-context';
import type { PostArchiveDataFragment } from 'types/graphql-type';

type Props = PostArchiveDataFragment;

// for type guard
type ExcerptBlockType = {
  id: string;
  slice_type: string;
  primary: {
    block_content: {
      text: string;
    };
  };
};

export const query = graphql`
  fragment PostArchiveData on PrismicPostDataType {
    title {
      text
    }
    sub_title {
      text
    }
    image {
      alt
      localFile {
        childImageSharp {
          fluid(maxWidth: 440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    meta {
      html
    }
    body {
      ... on PrismicPostBodyExcerptBlock {
        id
        slice_type
        primary {
          block_content {
            text
          }
        }
      }
    }
  }
`;

const isExcerptBlock: (item: any) => boolean = (
  item: any
): item is ExcerptBlockType => item?.slice_type === 'excerpt_block';

const useExcerpt = (body: Props['body']) => {
  const { isMobile } = React.useContext<MobileWidthContextProps>(
    MobileWidthContext
  );
  let excerpt = null;
  if (!isMobile && body) {
    const excerptBlock = body.find((element) => isExcerptBlock(element));
    if (excerptBlock !== undefined) {
      const defaultText = excerptBlock?.primary?.block_content?.text;
      if (defaultText) {
        excerpt = defaultText.replace(/\r?\n/g, '').substr(0, 300);
      }
    }
  }
  return excerpt;
};

export const ListItemContainer: React.FC<Props> = ({
  title,
  sub_title,
  image,
  meta,
  body,
}) => {
  // ListItemをPrismicApiと共通で使用するので、
  // ここで整形する
  const titleText = title ? title.text : undefined;
  const subTitleText = sub_title ? sub_title.text : undefined;
  const imageJSX = image?.localFile?.childImageSharp?.fluid ? (
    <Img
      fluid={image.localFile.childImageSharp.fluid as FluidObject}
      alt={image.alt ?? titleText ?? ''}
    />
  ) : undefined;
  const metaHtml = meta ? meta.html : undefined;
  const excerpt = useExcerpt(body);

  return (
    <ListItem
      title={titleText}
      sub_title={subTitleText}
      image={imageJSX}
      meta={metaHtml}
      excerpt={excerpt}
    />
  );
};

export default ListItemContainer;
