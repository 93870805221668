import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import type { FluidObject } from 'gatsby-image';
import { SliceZone } from 'src/components/post/slice-zone';
import type { PostEntryDataFragment } from 'types/graphql-type';

type Props = PostEntryDataFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment PostEntryData on PrismicPostDataType {
    sub_title {
      text
    }
    title {
      text
    }
    date
    image {
      alt
      dimensions {
        width
        height
      }
      localFile {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    body {
      ... on PrismicPostBodyNormalBlock {
        ...PostSliceZoneNormal
      }
      ... on PrismicPostBodyExcerptBlock {
        ...PostSliceZoneExcerpt
      }
    }
  }
`;

export const Entry: React.FC<Props> = ({
  className,
  sub_title,
  title,
  date,
  image,
  body,
}) => {
  const width = image?.dimensions?.width;
  const height = image?.dimensions?.height;
  return (
    <article className={className ?? ''}>
      {image?.localFile?.childImageSharp?.fluid && (
        <StyleImage>
          <Img
            className={
              width && height && width < height ? 'vertical' : undefined
            }
            fluid={image.localFile.childImageSharp.fluid as FluidObject}
            alt={image.alt ?? title?.text ?? ''}
          />
        </StyleImage>
      )}
      <StyleHeader>
        {sub_title?.text && <StyleSubTitle>{sub_title.text}</StyleSubTitle>}
        {title?.text && <StyleTitle>{title.text}</StyleTitle>}
      </StyleHeader>
      <StyleContent>
        {body && <SliceZone allSlices={body} />}
        {date && (
          <StyleDate>
            <time dateTime={dayjs(date).format('YYYY-MM-DD')}>
              {dayjs(date).format('YYYY.MM.DD')}
            </time>
          </StyleDate>
        )}
      </StyleContent>
    </article>
  );
};

const StyleImage = styled.figure`
  .gatsby-image-wrapper {
    > div {
      padding-bottom: ${(2 / 3) * 100}% !important;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
      &.vertical {
        max-height: 700px;
      }
    }
  }
`;

const StyleHeader = styled.header`
  text-align: center;
  margin-top: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 50px;
  }
`;

const StyleSubTitle = styled.p`
  line-height: 1.4;
  font-size: ${({ theme }) => theme.fontSize.xlarge.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xlarge.pc};
  }
`;

const StyleTitle = styled.h1`
  margin-bottom: 0;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.fontSize.xxxxlarge.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xxxxlarge.pc};
  }
`;

const StyleContent = styled.div`
  max-width: ${({ theme }) => theme.maxWidthText};
  margin-left: auto;
  margin-right: auto;
  font-size: ${({ theme }) => theme.fontSize.normal.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.normal.pc};
  }
`;

const StyleDate = styled.p`
  color: grey;
  padding-top: ${({ theme }) => theme.margin.content.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    padding-top: ${({ theme }) => theme.margin.content.pc};
  }
`;

export default Entry;
